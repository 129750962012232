<template>
	<div>
		<jy-dialog
			type="table"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_table"
			:width="global.dialogTabelWidth"
		>
			<div>
				<jy-query ref="checkForm" :model="formInline">
					<jy-query-item label="车牌号:" prop="plateNum" :span="1.5">
						<el-input v-model="formInline.plateNum" placeholder="请输入"></el-input>
					</jy-query-item>
					<jy-query-item label="所属机构:" prop="orgNa" :span="1.5">
						<div @click="selectInstitutions">
							<el-input placeholder="请选择" v-model="formInline.orgNa" readonly></el-input>
						</div>
					</jy-query-item>
					<template slot="search">
						<el-button type="primary" @click="oncheck">查询</el-button>
						<el-button type="primary" plain @click="resetForm('checkForm')">重置</el-button>
					</template>
				</jy-query>
				<jy-table max-height="595" :data="lists" @cell-click="save">
					<jy-table-column type="index" width="60" label="序号" align="center"></jy-table-column>
					<jy-table-column prop="plateNum" label="车牌号" min-width="100"></jy-table-column>
					<jy-table-column prop="vin" label="VIN" min-width="100"></jy-table-column>
					<jy-table-column prop="orgNa" label="所属机构" min-width="100"></jy-table-column>
					<jy-table-column prop="clientNames" label="关联设备" min-width="100"></jy-table-column>
				</jy-table>
				<jy-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="total"
				></jy-pagination>
			</div>
			<div slot="footer" class="dialog-footer"></div>
		</jy-dialog>
		<institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择机构"></institutions-tree>
	</div>
</template>
<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
	data() {
		return {
			title: "选择所属车辆",
			deviceId: "",
			clientId: "",

			formInline: {
				plateNum: "",
				orgNa: "",
				orgId: ""
			},

			lists: [],
			dialogVisible: false,
			formLabelWidth: "120px",
			// 分页
			pageIndex: 1,
			total: 100,
			pageSize: 10
		};
	},
	components: {
		institutionsTree
	},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("checkForm");
				this.deviceId = "";
				this.clientId = "";
			}
		}
	},
	activated() {
		this.getlist();
	},
	methods: {
		// 打开对话框
		init(row) {
			this.dialogVisible = true;
			this.deviceId = row.deviceId;
			this.clientId = row.clientId;
			this.getlist();
		},
		getlist() {
			let url = "/base/vehicle/queryPage";
			let option = {
				...this.formInline,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			this.$http.post(url, option).then(({ detail }) => {
				let list = detail.list.map(item => {
					item.clientNames = item.deviceInfos && item.deviceInfos.length && item.deviceInfos.map(item => item.clientId).join(",");
					return {
						...item,
						...item.sysOrg,
						...item.vehicleInfo
					};
				});
				this.lists = list;
				this.total = detail.total;
			});
		},
		// 机构查询
		addInstitutions(data) {
			// console.log(data)
			this.formInline.orgId = data.orgId;
			this.formInline.orgNa = data.orgNa;
		},
		// 选择机构树
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getlist();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formInline.orgId = "";
			this.oncheck();
		},
		save(row) {
			let url = "/base/device/update";
			let option = {
				vehicleId: row.vehicleId,
				deviceId: this.deviceId,
				clientId: this.clientId
			};
			this.$http.post(url, option).then(() => {
				this.$message({
					message: "绑定车辆成功",
					type: "success"
				});
				this.dialogVisible = false;
				this.$emit("updateList");
			});
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getlist();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.pageIndex = val;
			this.getlist();
		}
	}
};
</script>
<style lang="scss" scoped></style>
